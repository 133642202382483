<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup
          :label="'Base Options'"
          :closed="false"
        >
          <!--
          <InputWrapper
            type="info"
            v-bind:label="node.type"/> -->

          <InputWrapper
            type="color"
            :label="'Color'"
            :value="baseColor"
            @change="baseColor = $event"
          />

          <!-- <InputWrapper
            type="select"
            v-bind:label="'Blending'"
            v-bind:value="node.blending"
            v-bind:values="blendingOptions"
            v-on:change="onChange('map', $event)" /> -->

          <InputWrapper
            type="toggle"
            :label="'Double Sided'"
            :value="isDoubleSided"
            :extra="'Set the front/back face visibility for the material'"
            @change="onChangeSided"
          />

          <InputWrapper
            type="slider"
            :label="'Metalness'"
            :min="0"
            :max="1"
            :step="0.001"
            :value="metalness"
            @change="metalness = $event"
          />

          <InputWrapper
            type="slider"
            :label="'Roughness'"
            :min="0"
            :max="1"
            :step="0.001"
            :value="roughness"
            @change="roughness = $event"
          />

          <!-- <InputWrapper
            type="toggle"
            v-bind:label="'Transparent'"
            v-bind:value="transparent"
            v-on:change="normalMapEnabled = $event"/>

          <InputWrapper
            type="slider"
            v-bind:label="'Transmission'"
            v-bind:min="0"
            v-bind:max="1"
            v-bind:step="0.001"
            v-bind:value="Transmission"
            v-on:change="Transmission = $event"/> -->
        </BlockGroup>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './NodeSettingMixin'

export default {
  name: 'MaterialBlock',

  mixins: [mixin],

  computed: {

    transparent: {
      get () {
        return this.node.transparent
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.transparent = val
        })
      }
    },

    transmission: {
      get () {
        return this.node.transmission
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.transmission = val
        })
      }
    },

    blendingOptions () {
      return {
        0: 'None',
        1: 'Normal',
        2: 'Additive',
        3: 'Subtractive',
        4: 'Multiply'
      }
    },

    baseColor: {
      get () {
        return '#' + this.node.color.getHexString()
      },
      set (val) {
        this.gui.updateNode(() => {
          val = parseInt(val.substr(1), 16)
          this.node.color.setHex(val)
        })
      }
    },

    metalness: {
      get () {
        return this.node.metalness
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.metalness = val
        })
      }
    },

    roughness: {
      get () {
        return this.node.roughness
      },
      set (val) {
        this.gui.updateNode(() => {
          this.node.roughness = val
        })
      }
    },

    isDoubleSided () {
      return this.node.side > 0
    }
  },

  methods: {

    onChangeSided (event) {
      this.gui.updateNode(() => {
        if (this.isDoubleSided) {
          this.node.side = 0
        } else {
          this.node.side = 2
        }
      })
    }
  }
}

</script>
